import { Link } from 'react-router-dom'

function Footer() {

  return (

    <footer>
            <div className="footer">
                <div className="footer__container">

                    <ul className="footer__nav">
                        <Link to='/about'><li>About</li></Link>
                        <Link to='/web'><li>Web Developer</li></Link>
                        <Link to='/screenwriter'><li>Screenwriter</li></Link>
                        <Link to='/books'><li>Books</li></Link>
                        <Link to='/contact'><li>Contact</li></Link>
                        
                    </ul>
               

                <div className="footer__copyright">
                    <p>Copyright &copy; 2021. All rights reserved</p>
                    <p>matthew.lynewatt@gmail.com</p>
                    <br/>
                       <p></p>
                       <a href="https://icons8.com/icons" target="_blank">Icons by Icons8</a>
                       
                </div>
            
                
                    <ul className="footer__nav__social">
                        <li><a href="https://www.linkedin.com/in/matthew-lyne-watt/?originalSubdomain=cz" target="_blank" className=""><img src="https://img.icons8.com/color/48/000000/linkedin.png"/></a></li>
                        <li><a href="https://www.imdb.com/name/nm10190082/" target="_blank" className="" ><img src="https://img.icons8.com/color/48/000000/imdb.png"/></a></li>

                    </ul>
       

                 </div>
            </div>
        </footer>
    
      
  );

}

export default Footer;