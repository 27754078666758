import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import Footer from './Footer.jsx'


function Contact() {

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_uf0n34o', 'template_adbr2z4', e.target, 'user_P58zHLteSekcVPl41JrDt')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()

    }

  return (
    <>
   
     <div className="contact">
        <nav className="back"><Link to="/"><a><img src="https://img.icons8.com/ios/50/000000/circled-left.png"/></a></Link></nav>
          
       <div className="contact__container">
        <h1>Contact</h1>
         <form className="contact__form" onSubmit={sendEmail}>
           <label>I look forward to hearing from you</label>
            <input className="contact__input" type="text" name="subject" placeholder="subject" required />
     
            <input className="contact__input"  type="text" name="name" placeholder="name" required />
      
            <input className="contact__input"  type="email" name="email" placeholder="email" required/>
           
            <textarea className="contact__input"  name="message" placeholder="message" rows="5" required/>
            <input className="contact__btn" type="submit" value="Submit" />
        </form>
      </div>
    </div>
    <Footer />
        </>  

  );

}


export default Contact;