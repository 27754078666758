import { Link } from 'react-router-dom'
import Footer from './Footer.jsx'


function Books() {

  
  return (
    <>
    <div className="books">

       
         <nav className="back" ><Link to="/"><a><img src="https://img.icons8.com/ios/50/000000/circled-left.png"/></a></Link></nav>



     
       
       <div className="books__children">
         <h1>Books</h1>
       </div>
        
       <div className="books__container">
        
           <div className="books__header">
            <div>
            <h1>One BIG and three SMALL</h1>
            <h3>My new children's book is now available on Amazon!</h3>
            <button className="screenwriter__btn"><a href="https://www.amazon.com/dp/1399903128?ref_=pe_3052080_397514860" target="_blank">Order now!</a></button>
          </div>
          <img className="books__img" src="./img/front_cover.jpeg"/>
           </div>

        </div>
                
        <div className="books__container">

        <div className="books__synopsis">
          <div className="screenwriter__line"></div>
          <h2>The Blurb</h2>
          <p>Four mice gather to feast one night, but one greedy big mouse decides he should eat all the cheese for himself, and quickly falls out with his friends.
          </p>    
          <p>Left to eat crumbs and mouldy old bread, the smaller mice are determined to take action, even if it means waking up the big scary cat.</p>

          <br/>
          <p>Sneak Peak:</p>
              <blockquote><i>Inside of a house, at the end of a hall,
                 <br/>
                  Was a hole where some mice lived (one big and three small).
                 <br/>
                 <br/>
                  They gathered to feast on a dark, stormy night—
                   <br/>
                  But one mouse was eager. He gobbled each bite. 
                  </i></blockquote>
                  
             {/* <div className="books__synopsis"> */}
               <div className="screenwriter__line"></div>
            <h2>Illustrations</h2>
     
            <div className="books__images"> 
                        <div className="books__gallery">
                      <img src="./img/16.jpg" />
                    </div>          
                   <div className="books__gallery">
                     <img src="./img/mouse.jpg"/>
                     </div>  
                    {/* <div className="books__gallery">
                     <img src="./img/15.jpg" />
                    </div> */}
                    
         </div>
           <p className="books__copyright">The wonderful illustrations were created by <a href="https://www.congstoryboard.com/" target="_blank">Cong Nguyen</a>.	&copy;</p>
                    </div>          
          </div>
        </div>
        
      {/* </div> */}

      < Footer />

        </>  




  );

}


export default Books;