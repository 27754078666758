import { Link } from 'react-router-dom'
import Nav from './Nav'
import {useState} from 'react'
import '../style.scss'

function Hero() {

const text = document.getElementsByClassName('navbar__text')
const navBar = document.getElementsByClassName('navbar')
const [showNav, setShowNav] = useState(false);



  const handleClick = () => {
    if (showNav === false) {
      setShowNav(true)
      for (let i = 0; i < text.length; i += 1 ){
      text[i].style.display = 'block'; }
      for (let i = 0; i < navBar.length; i += 1 ){
      navBar[i].style.width = '16' + 'rem'; }
    } else {
      setShowNav(false)
      for (let i = 0; i < text.length; i += 1 ){
      text[i].style.display = 'none'; }
      for (let i = 0; i < navBar.length; i += 1 ){
      navBar[i].style.width = '5' + 'rem'; }
    }
  }

  return (
    <>


    <Nav />
   
    <div className="hero__center" id="hero">
          <div className="hero__item-center">
       
            <p>I'm Matthew Lyne-Watt,</p>
            <p>a web developer, screenwriter and self-published author based in Prague.</p>
            <p>I like creating things. Like this website.</p>
            
            <button className="hero__btn" onClick={handleClick}>Explore</button>
            <Link to="/contact"><button class="hero__btn">Contact</button></Link>
          </div>
        </div>
    
        <div className="hero__bottom">
          
     
          <div className="hero__item-bottom">
            <h1 className="hero__hello">Hello</h1>
            
          </div>
        </div>
          {/* <div className="hero__container">

            {/* <div id="text" className="hero__header">
                <h1>Welcome!</h1>
                <h1>Welcome!</h1> 
            </div> */}
        {/* </div>  */} 

  


        </>  





  );

}


export default Hero;