const quotesData = [
    {
        id: 1,
        quote: "There is only one plot-things are not what they seem.",
        cite: "Jim Thompson"
    },
    {
        id: 2,
        quote: "Everything I’ve written is personal- it’s the only way I know how to write.",
        cite: "Charlie Kaufman"
    },
    {
        id: 3,
        quote: "Being a good writer is 3% talent, 97% not being distracted by the internet.",
        cite: "Unknown"
    },
    {
        id: 4,
        quote: "Scriptwriting is the toughest part of the whole racket…the least understood and the least noticed.",
        cite: "Frank Capra"
    },
    {
        id: 5,
        quote: "Cooking is like love. It should be entered into with abandon or not at all.",
        cite: "Will Shetterly"
    },
    {
        id: 6,
        quote: "The screenplay is the child not only of its mother, the silent film, but also of its father, the drama.",
        cite: "Terrence Rattigan"
    },
    {
        id: 7,
        quote: "To eat is a necessity, but Screenwriting is an opportunity to fly first class, be treated like a celebrity, sit around the pool and be betrayed.",
        cite: "Ian McEwan"
    },
    {
        id: 8,
        quote: "We all eat, an it would I don’t think screenplay writing is the same as writing- I mean, I think it’s blue printing.",
        cite: "Robert Altman"
    },
    {
        id: 9,
        quote: "Writing is a marathon, not a sprint.",
        cite: "Robert McKee"
    },
    {
        id: 10,
        quote: "One of the big things you have learn is who to listen and when; and you can’t listen to everybody.",
        cite: "Amy Holden Jones"
    }

]

export default quotesData