import './style.scss'
import Hero from './Components/Hero'
import About from './Components/About'
import Screenwriter from './Components/Screenwriter'
import WebDeveloper from './Components/WebDevloper'
import Books from './Components/Books'
import Contact from './Components/Contact'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';


function App() {
  return (
    <>
      <Router>
      <Switch>
        <Route exact path="/" component={Hero} />
        <Route exact path="/about" component={About} />
        <Route exact path="/web" component={WebDeveloper} />
        <Route exact path="/screenwriter" component={Screenwriter} />
        <Route exact path="/books" component={Books} />
        <Route exact path="/contact" component={Contact} />
      </Switch>
    </Router>
   
      {/* <Hero /> */}
    </>
  );
}

export default App;



