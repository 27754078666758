import { Link } from 'react-router-dom'
import NavMain from './NavMain'
import Footer from './Footer.jsx'


function WebDeveloper() {

  return (
    <>
    {/* <NavMain /> */}

    <div className="web">
       <nav className="back"><Link to="/"><a><img src="https://img.icons8.com/ios/50/000000/circled-left.png"/></a></Link></nav>
      <div className="web__container">
        <div className="web__header">
         <h1><div className="web__line"></div>Web Developer<div className="web__line"></div></h1>
         <h2>01001000 01100101 01101100 01101100 01101111 00100001</h2>
         <h3>(Hello)</h3>
          
        </div>
         </div>
        <div className="web__content">
          <div className="web__box">
            <div className="web__line"></div>
            <h2>My Experience</h2>
            <p>I recently got into the world of web development after finishing a three month intensive Full Stack bootcamp in Prague. I landed my first job as a junior developer at Zeta Global.</p>
            <p>During my studies I completed 4 hackathons, 4 exams, and one final project. You can find the link to that project <a href="http://world-kitchen.codeboot.cz/" target="_blank"> here</a>.</p>  
            
            <p>I'm continuing to grow as a developer after every new project.</p>
            <p>I created this website using React.</p>
         
            <br/>
               <div className="web__line"></div>
             <h2>My Tech Stack</h2>
            <div className="web__tech">
             
              <div className="web__icons">
                <img src="/tech-logo/html.png" alt="HTML"/>
                <div className="web__name">HTML</div></div>
                 <div className="web__icons"><img src="./tech-logo/css.png" alt="CSS"/>
                 <div className="web__name">CSS</div></div>
                  <div className="web__icons"><img src="/tech-logo/javascript.png" alt="Javascript"/>
                  <div className="web__name">Javascript</div></div>
                   <div className="web__icons">
                <img src="/tech-logo/react.png" alt="React"/>
                <div className="web__name">React</div></div>
                 <div className="web__icons">
                <img src="/tech-logo/php.png" alt="PHP"/>
                <div className="web__name">PHP</div></div>
                 <div className="web__icons">
                <img src="/tech-logo/laravel.png" alt="laravel"/>
                <div className="web__name">Laravel</div></div>
                 <div className="web__icons">
                <img src="/tech-logo/mysql.png" alt="MySQL"/>
                <div className="web__name">MySQL</div></div>
                 <div className="web__icons">
                <img src="/tech-logo/git.png" alt="git"/>
                <div className="web__name">Git</div></div>
              
              

            </div>
            
            <div className="web__line"></div>
            <div className="web__contact">
              <h2>Reach out</h2>
                <p>If you're looking for help on your project, please <Link to="/contact"><a>get in touch</a></Link>!</p>
                {/* <button className="web__btn">Contact</button> */}
          
            </div>
            </div>

        </div>
       
    </div>
        < Footer />

        </>  




  );

}


export default WebDeveloper;