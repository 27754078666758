import { Link } from 'react-router-dom'
import {useState, useEffect} from 'react'
import quotesData from "../QUOTES.js";
import Carousel from "react-elastic-carousel";
import Item from "./Item";
import Footer from './Footer.jsx'



function Screenwriter() {
    const [quoteIndex, setQuoteIndex] = useState(0)
    const [modal, setModal] = useState(false)
    const [modal2, setModal2] = useState(false)
    const [modal3, setModal3] = useState(false)
    const [modal4, setModal4] = useState(false)
    const [modal5, setModal5] = useState(false)
    const [modal6, setModal6] = useState(false)

  useEffect(() => {
    setInterval(() =>
    setQuoteIndex(Math.floor(Math.random() * quotesData.length)), 7000)
  },[])

  const toggleModal = () => {
      setModal(!modal);
   
  }
  const toggleModalTwo = () => {
      setModal2(!modal2);
  }
    const toggleModalThree = () => {
      setModal3(!modal3);
  }
    const toggleModalFour = () => {
      setModal4(!modal4);
  }
    const toggleModalFive = () => {
      setModal5(!modal5);
  }
    const toggleModalSix = () => {
      setModal6(!modal6);
  }

   if(modal) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

  return (
    <>


    <div className="screenwriter">
        <nav className="back"><Link to="/"><a><img src="https://img.icons8.com/ios/50/000000/circled-left.png"/></a></Link></nav>
        <div className="screenwriter__container">
            <div className="screenwriter__header">
                 <h1>Screenwriter</h1>
                
                 
            </div>
            {/* <Link to="/contact"> <button className="screenwriter__btn">Contact Me</button></Link> */}
        
            { 
            <div className="screenwriter__quote">
                <blockquote>"{quotesData[quoteIndex].quote}"</blockquote>
                <cite>- {quotesData[quoteIndex].cite}</cite>     
            </div>
            }




        </div>
                <div className="screenwriter__main">
                    
                    <div  className="screenwriter__box">
                        <h2><div className="screenwriter__line"></div>So you want to make a movie...</h2>
                        <p>Great! As Alfred Hithcock famously said;<span> "To make a great film you need three things; the script, the script, and the script”.</span></p>
                        <p>Screenwriting is instrumental in the filmmaking process, and it's important to make sure that you get started with a solid script. I'm here to provide my services to <span>help you form a fantastic and unique story.</span></p>
                        <p>I have several years experience in screenwriting and script consultancy. Some of my films have gone on to be successful in international festivals and have won global awards.
                            With no genre or theme I'm afraid of exploring, I consider myself to be a <span>versatile and adaptable writer.</span> I put in the reseach, the time and care, to make the script the best it could possibly be.
                        </p>
                        <div className="screenwriter__line"></div>
                    </div>

                    </div>
                    <h2>Past Projects</h2>
                     <div  className="screenwriter__projects">
                         
                         <Carousel breakPoints={breakPoints}>
                                <Item><div className="screenwriter__poster">
                                <img src="/posters/stakeouts.jpg" onClick={toggleModal}/>
                    
                                {modal && (
                                     <div className="modal">
                                        <div onClick={toggleModal} className="overlay"></div>
                                        <div className="modal-content">
                                            <h3>Stakeouts, 2021</h3>
                                            <div className="modal-content-flex"> 
                                            <img src="/posters/stakeouts.jpg"/>
                                            <ul>
                                                <li>Web Series</li>
                                                <li>Directed by: Steen Agro</li>
                                                <li><a href="https://www.imdb.com/title/tt15330244/?ref_=nm_flmg_wr_3" target="_blank">imdb</a></li>
                                            </ul>
                                    
                                            </div>
                                    
                                            <button className="close-modal" onClick={toggleModal}>X</button>
                                            </div>
                                                </div>

                                            )}
                                        </div>
                                        </Item>

                                    <Item><div className="screenwriter__poster" onClick={toggleModalTwo}>
                                <img src="/posters/tml.jpg"/>
                                 {modal2 && (
                                     <div className="modal">
                                        <div onClick={toggleModalTwo} className="overlay"></div>
                                        <div className="modal-content">
                                            <h3>Three Monkeys Later, 2019</h3>
                                            <div className="modal-content-flex"> 
                                            <img src="/posters/tml.jpg"/>
                                            <ul>
                                                <li>Short Film</li>
                                                <li>Directed by: Matthew Lyne-Watt + Spencer Derr</li>
                                                <li>Three non-voters express their fears and desires over the three years of Brexit negotiations.</li>
                                                <li><a href="https://www.imdb.com/title/tt11034826/" target="_blank">imdb</a></li>
                                            </ul>
                                    
                                            </div>
                                    
                                            <button className="close-modal" onClick={toggleModalTwo}>X</button>
                                            </div>
                                    </div>

                                )}
                            
                            </div></Item>
                             <Item> <div className="screenwriter__poster" onClick={toggleModalThree}>
                                <img src="/posters/heretic.jpg"/>
                                 {modal3 && (
                                     <div className="modal">
                                        <div onClick={toggleModalThree} className="overlay"></div>
                                        <div className="modal-content">
                                            <h3>The Heretic, 2020</h3>
                                            <div className="modal-content-flex"> 
                                            <img src="/posters/heretic.jpg"/>
                                            <ul>
                                                <li>Short Film</li>
                                                <li>Directed by: Steen Agro</li>
                                                <li>Winner: World 48hr Competition, 2020</li>
                                                <li><a href="https://www.imdb.com/title/tt12452790/?ref_=nm_flmg_wr_4" target="_blank">imdb</a></li>
                                            </ul>
                                    
                                            </div>
                                    
                                            <button className="close-modal" onClick={toggleModalThree}>X</button>
                                            </div>
                                    </div>

                                )}
                            
                            </div>
                            </Item>
                                    <Item><div className="screenwriter__poster" onClick={toggleModalFour}>
                                <img src="/posters/bwl.jpg"/>
                                 {modal4 && (
                                     <div className="modal">
                                        <div onClick={toggleModalFour} className="overlay"></div>
                                        <div className="modal-content">
                                            <h3>Before We Left, 2018</h3>
                                            <div className="modal-content-flex"> 
                                            <img src="/posters/bwl.jpg"/>
                                            <ul>
                                                <li>Short Film</li>
                                                <li>Directed by: Arjun Sarabhai Verma</li>
                                                <li>Winner: Oniros Annual Awards Best Film Under 5 mins, 2018</li>
                                                <li><a href="https://www.imdb.com/title/tt9306006/?ref_=nm_flmg_wr_10" target="_blank">imdb</a></li>
                                            </ul>
                                    
                                            </div>
                                    
                                            <button className="close-modal" onClick={toggleModalFour}>X</button>
                                            </div>
                                    </div>

                                )}
                            
                            </div>
                            </Item>
                                    <Item><div className="screenwriter__poster" onClick={toggleModalFive}>
                                <img src="/posters/sirens.jpg"/>
                                 {modal5 && (
                                     <div className="modal">
                                        <div onClick={toggleModalFive} className="overlay"></div>
                                        <div className="modal-content">
                                            <h3>Sirens, 2019</h3>
                                            <div className="modal-content-flex"> 
                                            <img src="/posters/sirens.jpg"/>
                                            <ul>
                                                <li>Short Film</li>
                                                <li>Directed by: Steen Agro</li>
                                                <li>Winner: Praha 48hr Competition: 2nd Place</li>
                                                <li><a href="https://www.imdb.com/title/tt13147606/?ref_=nm_flmg_wr_5" target="_blank">imdb</a></li>
                                            </ul>
                                    
                                            </div>
                                    
                                            <button className="close-modal" onClick={toggleModalFive}>X</button>
                                            </div>
                                    </div>

                                )}
                            
                            </div></Item>
                                    <Item><div className="screenwriter__poster" onClick={toggleModalSix}>
                                <img src="/posters/andyhilt.jpg"/>
                                 {modal6 && (
                                     <div className="modal">
                                        <div onClick={toggleModalSix} className="overlay"></div>
                                        <div className="modal-content">
                                            <h3>The Andy Hilt Show, 2021</h3>
                                            <div className="modal-content-flex"> 
                                            <img src="/posters/andyhilt.jpg"/>
                                            <ul>
                                                <li>Short Film/Mockumentary</li>
                                                <li>Directed by: Robert Murphy</li>
                                              
                                                <li><a href="https://www.imdb.com/title/tt11414320/?ref_=nm_flmg_wr_1" target="_blank">imdb</a></li>
                                            </ul>
                                    
                                            </div>
                                    
                                            <button className="close-modal" onClick={toggleModalSix}>X</button>
                                            </div>
                                    </div>

                                )}
                            
                            </div> </Item>
                         
                                </Carousel>
        
                    </div>

                    <div className="screenwriter__testimonials">   
                            <div className="screenwriter__row">
                            <h2>Testimonials</h2>
                            </div>
                            <div className="screenwriter__row">
                        <div className="screenwriter__block">
                            
                            <blockquote className="screenwriter__blockquote">
                                Matt did a great job on this project. He brought a lot of experience, great suggestions and advice. I'm very pleased and would highly recommend for anyone looking for a quality script!
                                <cite>- Ash Gilpin</cite>
                            </blockquote>
                        </div>
                        <div className="screenwriter__block">
                            <blockquote className="screenwriter__blockquote">
                                Matthew was on top of things from the get go. My inexperience with narrative scripting didn't matter since Matt was very professional and helped guide me to where I needed to go. He took my disjointed story idea and turned it into a nicely packaged script. Highly recommend!
                                <cite>- FourString Media</cite>
                            </blockquote>
                        </div>
                        <div className="screenwriter__block">
                            <blockquote className="screenwriter__blockquote">
                                It was amazing with Matthew. He turned my simple idea to a full blown screenplay! Now I have an amazing story which means the world to me.
                                <cite>- JayeFoo</cite>
                            </blockquote>
                            <br/>
                        </div>
                    </div>
                    </div>
                  
                   
                <div className="screenwriter__contact">   
                    <div className="screenwriter__info">
                                           
                       <h2>What are you waiting for?<div className="screenwriter__line"></div></h2>
                        <p>I'd be happy to talk about your project, whatever it may be.</p>
                        <p>I also have several spec features, TV pilots, webseries and short scripts for your viewing pleasure.</p>
                        <br />
                        <Link to="/contact"> <button className="screenwriter__btn-touch">Get in touch</button></Link>
                        {/* <br/>
                        <Link to="/"> <button className="screenwriter__btn-touch">Back to home</button></Link> */}

                    </div>
                        
                    </div>
         
     


    </div>
        < Footer />

        </>  




  );

}


export default Screenwriter;