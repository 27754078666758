import {useState} from 'react'
import { Link } from 'react-router-dom'


function Nav() {


  return (

   
        <nav className="navbar">
            <ul className="navbar__list">
            <li className="navbar__welcome"><a className="navbar__link__welcome"><img src="https://img.icons8.com/ios/50/000000/so-so.png"/><span className="navbar__text">Welcome!</span> </a></li> 
            <li className="navbar__item"><Link to="/web"><a className="navbar__link"><img src="https://img.icons8.com/dotty/80/000000/developer.png"/><span className="navbar__text">Web Developer</span></a></Link></li>
            <li className="navbar__item"><Link to="/screenwriter"><a className="navbar__link"><img src="https://img.icons8.com/dotty/80/000000/typewriter-with-paper.png"/><span className="navbar__text">Screenwriter</span></a></Link></li>
            <li className="navbar__item"><Link to="/books"><a className="navbar__link"><img src="https://img.icons8.com/dotty/80/000000/book.png"/><span className="navbar__text">Books</span></a></Link></li>
            <li className="navbar__item"><Link to="/about"><a className="navbar__link"><img src="https://img.icons8.com/dotty/80/000000/guest-male.png"/><span className="navbar__text">About</span></a></Link></li>
            <li className="navbar__item"><Link to="/contact"><a className="navbar__link"><img src="https://img.icons8.com/ios/50/000000/phone.png"/><span className="navbar__text">Contact</span></a></Link></li>
            </ul>
        </nav>

    
            // <a href="https://icons8.com/icons">Icon by Icons8</a>
      
  );

}

export default Nav;


