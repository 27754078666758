import { Link } from 'react-router-dom'
import Footer from './Footer.jsx'

function About() {

  return (
    <>
 <nav className="back"><Link to="/"><a><img src="https://img.icons8.com/ios/50/000000/circled-left.png"/></a></Link></nav>
    <div className="about">
     
      <div className="about__container">
     
        <div className="about__hello">
          <h1>My name is Matthew Lyne-Watt</h1>
          <h5>and I like creating things. Like this website.</h5>
        </div> 
        
        <div className="about__facts">
          <div>
            <div className="about__line"></div>
          <p>I'm from the Nottingham, UK.</p>
          <p>I currently live in Prague, Czech Republic with my wife.</p>
          <p>I enjoy taking small hikes, playing board games, and watching movies. And drinking Czech beer.</p>
          <p>I also like pizza.</p>
          <div className="about__line"></div>

          <br/>
            <div className="about__contact">
           <p>If you're looking for a creative touch on your next project, then <Link to="/contact"><a>get in touch</a></Link>!</p>
            {/* <button className="about__btn">Get in touch</button>  */}
           </div>
        </div>
        <img className="about__img" src="./img/matthew.jpg" alt="matthew"/>
        </div>
          
         </div>
           
    </div>
  

        </>  




  );

}


export default About;